import React from "react"
import { graphql } from "gatsby"
import { IGatsbyImageData } from "gatsby-plugin-image"
import {
  Banner,
  Helmet,
  ReadMoreLink,
  Section,
  Container,
  Row,
  Box,
  Intro,
  routesObject,
  Announcement
} from "@life-without-barriers/react-components"

import { FosterCareForm } from "@life-without-barriers/shared-contact-form"
import {
  CarerSupportItemsJson,
  CarerSupportItem,
  Site,
  PageAnnouncement
} from "../../contentTypes"
import Layout from "../../components/foster-care/Layout"

interface PageProps {
  location: { pathname: string }
  data: {
    headerImage: IGatsbyImageData
    site: Site
    allCarerSupportJson: CarerSupportItemsJson
    announcement: PageAnnouncement
  }
}

const SupportItem = ({ header, text }: CarerSupportItem) => (
  <div className="t-support-item w-100 w-50-ns pt3 pr4-ns">
    <h2 className="ts-display-4 pv3">{header}</h2>
    <div className="copy pb3">{text}</div>
  </div>
)

const CarerSupportForm = () => (
  <FosterCareForm
    formId="foster-care-carer-support"
    formTitle="Contact us to learn more about foster caring"
  />
)

const CarerSupportPage = ({
  location,
  data: {
    allCarerSupportJson,
    headerImage,
    site: { siteMetadata },
    announcement
  }
}: PageProps) => {
  const supportData = allCarerSupportJson.edges

  return (
    <Layout phoneNumber="1300592227" contactForm={<CarerSupportForm />}>
      <div className="support-us">
        <Helmet
          title={`Support for foster carers - Training, respite, on-call | ${siteMetadata.title}`}
          description="Our foster carers are never alone in their carer journey. We provide training, 24/7 on-call support, financial support, clinical assistance, respite care and carer networking."
          image={headerImage}
          siteUrl={siteMetadata.siteUrl}
          path={location.pathname}
        />
        <Banner
          title="Carer support"
          image={headerImage}
          breadcrumbs={[routesObject.fosterCareHome]}
        />

        <Container>
          <Row className="flex-wrap">
            <Box className="t-other-ways w-100 w-two-thirds-l">
              <div className="pr4-ns">
                <Intro
                  text="Our foster carers are never alone in their caring journey. It
                can be difficult to comprehend the experiences children bring
                with them, so it is important that carers feel supported and
                confident in their role."
                />
              </div>
              <div className="flex flex-wrap">
                {supportData.map(({ node }, key) => {
                  return (
                    <SupportItem
                      key={key}
                      header={node.header}
                      text={node.text}
                    />
                  )
                })}
              </div>
              <div className="pt5">
                {announcement && (
                  <Announcement insideBoxComponent={true} {...announcement} />
                )}
              </div>
            </Box>
            <Box className="w-100 w-third-l mt4 mt0-l">
              <FosterCareForm
                formId="foster-care-carer-support"
                formTitle="Contact us to learn more about foster caring"
              />
            </Box>
          </Row>
        </Container>
        <Section background="bg-lwb-grey-xxx-light">
          <Container>
            <Row>
              <Box>
                <h2 className="ts-display-4 pb3">FAQ about Foster Care</h2>
                <div className="copy fw4 pb3">
                  Frequently asked questions by our carer community on a range
                  of topics, including about the foster care process, carer
                  support, everyday caring, and legal matters.
                </div>
                <ReadMoreLink
                  className="color-lwb-theme-darker"
                  href="/foster-care/support/carer-guide"
                />
              </Box>
            </Row>
          </Container>
        </Section>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  {
    allCarerSupportJson {
      edges {
        node {
          header
          text
        }
      }
    }
    headerImage: file(relativePath: { regex: "/carer-support.jpg/" }) {
      childImageSharp {
        gatsbyImageData(quality: 90, width: 1440)
      }
    }
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    announcement: contentfulAnnouncement(page: { eq: "caring-space" }) {
      ...ContentfulAnnouncement
    }
  }
`

export default CarerSupportPage
